import { render, staticRenderFns } from "./leader.vue?vue&type=template&id=daf1e568&scoped=true"
import script from "./leader.vue?vue&type=script&lang=js"
export * from "./leader.vue?vue&type=script&lang=js"
import style0 from "./leader.vue?vue&type=style&index=0&id=daf1e568&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daf1e568",
  null
  
)

export default component.exports